import logo from './logo.svg';
import './App.css';
import axios from 'axios';
import React from 'react';
import Nav from './components/navbar/nav'
import Cover from './components/sections/cover'
import BreakingNews from './components/sections/BreakingNews'
import Lastnews from './components/sections/lastnews'
import Footer from './components/sections/footer'
import Googleads from "./components/googleads/bannerads"
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Learn from './/components/sections/Learn'
import CryptoPriceAnalysis from './/components/sections/CryptoPriceAnalysis'
import Shownews from './components/sections/shownews'
import AddPost from "./components/AddPost"
function App() {
  return (
    <div className='container mx-auto px-4'>
      
      <Nav/>
      <div >
     

  <AddPost/>
     
      

   
      </div>
     
    </div>
  );
}

export default App;
