import React, { useState, useEffect } from "react";

const App = () => {
  const [data, setData] = useState([]);
  const [name, setName] = useState("");
  const [status, setStatus] = useState("");

  // Fetch data from the backend
  useEffect(() => {
    fetch("https://dailynewscrypto.net/api.php") // Update with your PHP script URL
      .then((response) => response.json())
      .then((data) => setData(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  // Handle form submission to add new data
  const handleSubmit = (event) => {
    event.preventDefault();

    fetch("https://dailynewscrypto.net/api.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name: name }), // Pass name from state
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          setStatus("Data added successfully!");
          setName(""); // Reset form
          // Optionally, re-fetch data or append to state
          setData([...data, { name: name }]);
        } else {
          setStatus("Error: " + result.message);
        }
      })
      .catch((error) => {
        setStatus("Error: " + error.message);
      });
  };

  return (
    <div className="app">
      <h1>React PHP CRUD App</h1>

      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Enter name"
        />
        <button type="submit">Add Data</button>
      </form>

      {status && <p>{status}</p>}

      <h2>Data List</h2>
      <ul>
        {data.map((item, index) => (
          <li key={index}>{item.name}</li>
        ))}
      </ul>
    </div>
  );
};

export default App;
